import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-[#fab400] p-4 sm:p-6">
      <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-4">Privacy Policy for Action for Happiness Calendar App</h1>
        
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
          <p>We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our Action for Happiness Calendar App.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Information We Collect</h2>
          <p>We collect minimal information to provide you with our service:</p>
          <ul className="list-disc list-inside">
            <li><strong>Calendar Events</strong>: When you choose to add events to your calendar, we temporarily access the event data to facilitate this process.</li>
            <li><strong>Language Preference</strong>: We store your language preference locally on your device to provide a personalized experience.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">How We Use Your Information</h2>
          <p>We use your information solely to:</p>
          <ul className="list-disc list-inside">
            <li>Add events to your calendar when you request this action.</li>
            <li>Display the app in your preferred language.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Data Storage and Security</h2>
          <ul className="list-disc list-inside">
            <li>We do not store any of your personal information or calendar data on our servers.</li>
            <li>All operations are performed locally on your device or through secure Google APIs.</li>
            <li>We use industry-standard security measures to protect your data during transmission.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Third-Party Services</h2>
          <p>We use Google Calendar API to add events to your calendar. Please refer to Google's Privacy Policy for information on how they handle your data.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Your Rights</h2>
          <p>You have the right to:</p>
          <ul className="list-disc list-inside">
            <li>Choose not to add events to your calendar.</li>
            <li>Change your language preference at any time.</li>
            <li>Use the app without providing any personal information.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Changes to This Policy</h2>
          <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at szczepan@duck.com.</p>
        </section>

        <p className="text-sm text-gray-600">Last updated: 04.09.2024</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;