import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { fetchAllEvents, handleAddToCalendar, LANGUAGE_COLUMNS } from './googleCalendar';
import { LANGUAGES, detectUserLanguage, getMonthName, translations } from './languages';
import { analytics } from './firebase-config';
import { logEvent } from 'firebase/analytics';

function LanguageDropdown({ language, setLanguage }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      } 
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          id="language-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          {language.toUpperCase()}
          <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="language-menu">
            {Object.entries(LANGUAGES).map(([key, value]) => (
              <button
                key={key}
                onClick={() => {
                  setLanguage(value);
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                {key}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function MainPage() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentEvent, setCurrentEvent] = useState(null);
  const [calendarStatus, setCalendarStatus] = useState('');
  const [allEvents, setAllEvents] = useState([]);
  const [language, setLanguage] = useState(detectUserLanguage());
  const [isLoading, setIsLoading] = useState(true);
  const cardRef = useRef(null);
  const [startX, setStartX] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const updateCurrentEvent = useCallback((events, date) => {
    const dateString = date.toISOString().split('T')[0];
    const event = events.find(event => event[0] === dateString);
    
    if (event) {
      setCurrentEvent({
        date: event[0],
        description: event[LANGUAGE_COLUMNS[language]]
      });
    } else {
      setCurrentEvent(null);
    }
  }, [language]);

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true);
      try {
        const events = await fetchAllEvents();
        setAllEvents(events);
        updateCurrentEvent(events, currentDate);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, [currentDate, updateCurrentEvent]);

  const handleAddToCalendarClick = async () => {
    if (allEvents.length > 0) {
      try {
        setCalendarStatus(translations[language].addingEvents);
        const result = await handleAddToCalendar(allEvents, language);
        if (result && result.length > 0) {
          setCalendarStatus(translations[language].eventsAdded);
          // Log successful calendar add event
          logEvent(analytics, 'events_added_to_calendar', {
            language: language,
            event_count: result.length
          });
        } else {
          setCalendarStatus(translations[language].noEventsAdded);
        }
      } catch (error) {
        console.error('Error adding events to calendar:', error);
        setCalendarStatus(`${translations[language].addEventError}${error.message || 'Unknown error'}`);
        // Log error event
        logEvent(analytics, 'add_to_calendar_error', {
          language: language,
          error_message: error.message || 'Unknown error'
        });
      }
    }
  };

  const handleDragStart = (e) => {
    setStartX(e.type.includes('mouse') ? e.pageX : e.touches[0].clientX);
    setIsDragging(true);
  };

  const handleDragMove = (e) => {
    if (!isDragging) return;
    
    const currentX = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
    const diff = startX - currentX;

    if (Math.abs(diff) > 50) {
      const newDate = new Date(currentDate);
      newDate.setDate(newDate.getDate() + (diff > 0 ? 1 : -1));
      setCurrentDate(newDate);
      updateCurrentEvent(allEvents, newDate);
      setIsDragging(false);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <div className="min-h-screen bg-[#fab400] p-4 sm:p-6 flex flex-col items-center justify-center relative overflow-hidden">
      {/* Language Dropdown in top-right corner */}
      <div className="absolute top-4 right-4 z-20">
        <LanguageDropdown language={language} setLanguage={setLanguage} />
      </div>

      {/* Background pattern */}
      <div className="absolute inset-0 z-0 opacity-20">
        <div className="absolute inset-0 bg-white transform rotate-45 scale-150">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-400 via-purple-400 to-pink-400 transform -rotate-45 scale-150"></div>
        </div>
      </div>
      
      {/* Content */}
      <div className="relative z-10 w-full max-w-md mx-auto">
        <h1 className="text-6xl sm:text-7xl font-bold text-left mb-2 sm:mb-3 text-[#392e44]" style={{ fontFamily: "'Poppins', sans-serif" }}>{translations[language].title}</h1>
        
        {/* Added header */}
        <p className="text-lg sm:text-xl text-left mb-6 sm:mb-8 text-[#392e44]" style={{ fontFamily: "'Poppins', sans-serif" }}>{translations[language].header}</p>
        
        <div 
          className="relative w-full mx-auto mb-6 sm:mb-8 cursor-grab active:cursor-grabbing transform transition-transform hover:scale-105"
          ref={cardRef}
          onMouseDown={handleDragStart}
          onMouseMove={handleDragMove}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
          onTouchStart={handleDragStart}
          onTouchMove={handleDragMove}
          onTouchEnd={handleDragEnd}
        >
          <div className="absolute inset-0 bg-white shadow-lg transform -skew-y-3 sm:-skew-y-6 rounded-2xl sm:rounded-3xl"></div>
          <div className="relative bg-white shadow-lg rounded-2xl sm:rounded-3xl overflow-hidden">
            {/* Calendar header */}
            <div className="bg-white text-[#392e44] p-3 sm:p-4 text-center">
              <p className="text-xl sm:text-2xl font-bold" style={{ fontFamily: "'Poppins', sans-serif" }}>{getMonthName(currentDate, language)}</p>
              <p className="text-4xl sm:text-5xl font-bold" style={{ fontFamily: "'Poppins', sans-serif" }}>{currentDate.getDate()}</p>

            </div>
            
            {/* Calendar content */}
            <div className="p-6 sm:p-6 bg-[#db2350] flex items-center justify-center min-h-[200px]">
              {isLoading ? (
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
              ) : currentEvent ? (
                <p className="text-white text-4xl sm:text-4xl font-medium text-left" style={{ fontFamily: "'Poppins', sans-serif" }}>{currentEvent.description}</p>
              ) : (
                <p className="text-gray-500 text-lg sm:text-xl text-center">{translations[language].noEvent}</p>
              )}
            </div>
            
            {/* Tear-off effect */}
            <div className="absolute bottom-0 left-0 right-0 h-3 sm:h-4"></div>
          </div>
        </div>

        <button
          onClick={handleAddToCalendarClick}
          className="w-full px-4 py-2 border border-transparent text-sm sm:text-base font-medium rounded-md text-white bg-gradient-to-r from-green-500 to-emerald-600 hover:from-green-600 hover:to-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transform transition-transform hover:scale-105 shadow-lg"
        >
          {translations[language].addToCalendar}
        </button>
        {calendarStatus && <p className="text-green-700 mt-2 font-semibold text-center">{calendarStatus}</p>}
      </div>

      {/* Added footer */}
{/* Footer */}
<footer className="mt-8 text-center text-sm absolute bottom-4 left-0 right-0 z-20">
  <Link 
    to="/privacy" 
    className="mr-4 text-[#392e44] hover:text-[#db2350] transition-colors duration-200 ease-in-out"
  >
    Privacy Policy
  </Link>
  <Link 
    to="/terms" 
    className="text-[#392e44] hover:text-[#db2350] transition-colors duration-200 ease-in-out"
  >
    Terms and Conditions
  </Link>
</footer>
    </div>
  );
}

export default MainPage;