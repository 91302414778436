import React from 'react';

function TermsAndConditions() {
  return (
    <div className="min-h-screen bg-[#fab400] p-4 sm:p-6">
      <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-4">Terms and Conditions for Action for Happiness Calendar App</h1>
        
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">1. Acceptance of Terms</h2>
          <p>By using the Action for Happiness Calendar App, you agree to these Terms and Conditions. If you don't agree, please don't use the app.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">2. Use of the App</h2>
          <p>You may use this app for personal, non-commercial purposes only. You agree not to misuse the app or help anyone else do so.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">3. User Account</h2>
          <p>To use certain features of the app, you may need to sign in with your Google account. You are responsible for maintaining the confidentiality of your account.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">4. Intellectual Property</h2>
          <p>The app, including its content and features, is owned by us and is protected by copyright, trademark, and other laws. You may not copy, modify, or distribute any part of the app without our prior written consent.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">5. User Content</h2>
          <p>You are responsible for any content you add to your calendar through our app. You must have the necessary rights to such content.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">6. Privacy</h2>
          <p>Your use of the app is also governed by our Privacy Policy. Please review it to understand how we collect and use your information.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">7. Third-Party Services</h2>
          <p>Our app uses Google Calendar API. Your use of this service is subject to Google's terms and conditions.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">8. Disclaimer of Warranties</h2>
          <p>The app is provided "as is" without any warranties, expressed or implied.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">9. Limitation of Liability</h2>
          <p>To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of the app.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">10. Changes to the App and Terms</h2>
          <p>We may modify the app or these terms at any time. We'll notify you of significant changes to these terms.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">11. Termination</h2>
          <p>We may terminate or suspend your access to the app at any time, without prior notice, for any reason.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">12. Governing Law</h2>
          <p>These terms are governed by the laws of Argentina, without regard to its conflict of law provisions.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">13. Contact Information</h2>
          <p>For any questions about these Terms, please contact us at szczepan@duck.com.</p>
        </section>

        <p className="text-sm text-gray-600">Last updated: 04.09.2024</p>
      </div>
    </div>
  );
}

export default TermsAndConditions;