import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainPage from './MainPage';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import { initGoogleApi } from './googleCalendar';
import { analytics } from './firebase-config';
import { logEvent } from 'firebase/analytics';

function App() {
  const [authLoading, setAuthLoading] = useState(true);
  const [authError, setAuthError] = useState(null);

  useEffect(() => {
    const initAuth = async () => {
      try {
        await initGoogleApi();
        setAuthLoading(false);
        // Log a test event for Firebase Analytics
        logEvent(analytics, 'app_initialized', {
          method: 'Google API'
        });
        console.log('Firebase Analytics test event logged: app_initialized');
      } catch (error) {
        console.error('Error initializing Google API:', error);
        setAuthError(error.message);
        setAuthLoading(false);
        // Log the error event
        logEvent(analytics, 'app_initialization_error', {
          error_message: error.message
        });
      }
    };

    initAuth();

    // Log app_opened event
    logEvent(analytics, 'app_opened');
    console.log('Firebase Analytics event logged: app_opened');
  }, []);

  if (authLoading) {
    return <div>Loading authentication...</div>;
  }

  if (authError) {
    return <div>Error: {authError}</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;