// languages.js
export const LANGUAGES = {
    EN: 'en',
    PL: 'pl',
    RU: 'ru',
    ES: 'es',
    DE: 'de',
    ZH: 'zh',
    HI: 'hi',
    PT: 'pt'
  };
  
  export function detectUserLanguage() {
    const browserLang = navigator.language || navigator.userLanguage;
    const langCode = browserLang.split('-')[0];
    return Object.values(LANGUAGES).includes(langCode) ? langCode : LANGUAGES.EN;
  }
  
  export function getMonthName(date, lang) {
    return date.toLocaleString(lang, { month: 'long' });
  }
  
  export function getWeekdayName(date, lang) {
    return date.toLocaleString(lang, { weekday: 'long' });
  }
  
  export const translations = {
    [LANGUAGES.EN]: {
      title: 'Action for Happiness',
      header: 'Add Action for Happiness activities to your calendar for a whole month.',
      noEvent: 'No event for this day',
      addToCalendar: 'Add all events to calendar',
      addingEvents: 'Adding events...',
      eventsAdded: 'Events added to calendar successfully!',
      addEventError: 'Failed to add events to calendar: ',
      noEventsAdded: 'No events were added to the calendar.',
    },
    [LANGUAGES.PL]: {
      title: 'Action for Happiness',
      noEvent: 'Brak wydarzenia na ten dzień',
      addToCalendar: 'Dodaj wszystkie wydarzenia do kalendarza',
      addingEvents: 'Dodawanie wydarzeń...',
      eventsAdded: 'Wydarzenia dodane do kalendarza pomyślnie!',
      addEventError: 'Nie udało się dodać wydarzeń do kalendarza: ',
      noEventsAdded: 'Żadne wydarzenia nie zostały dodane do kalendarza.',
      header: 'Dodaj działania Action for Happiness do swojego kalendarza na cały miesiąc.',
    },
    [LANGUAGES.RU]: {
      title: 'Действие для счастья',
      noEvent: 'Нет события на этот день',
      addToCalendar: 'Добавить все события в календарь',
      addingEvents: 'Добавление событий...',
      eventsAdded: 'События успешно добавлены в календарь!',
      addEventError: 'Не удалось добавить события в календарь: ',
      noEventsAdded: 'В календарь не было добавлено ни одного события.',
      header: 'Добавьте мероприятия Action for Happiness в свой календарь на целый месяц.',
    },
    [LANGUAGES.ES]: {
      title: 'Acción para la Felicidad',
      noEvent: 'No hay evento para este día',
      addToCalendar: 'Agregar todos los eventos al calendario',
      addingEvents: 'Agregando eventos...',
      eventsAdded: '¡Eventos agregados al calendario con éxito!',
      addEventError: 'No se pudieron agregar eventos al calendario: ',
      noEventsAdded: 'No se agregaron eventos al calendario.',
      header: 'Añade actividades de Action for Happiness a tu calendario durante un mes entero.',
    },
    [LANGUAGES.DE]: {
      title: 'Aktion für Glück',
      noEvent: 'Kein Ereignis für diesen Tag',
      addToCalendar: 'Alle Ereignisse zum Kalender hinzufügen',
      addingEvents: 'Ereignisse werden hinzugefügt...',
      eventsAdded: 'Ereignisse erfolgreich zum Kalender hinzugefügt!',
      addEventError: 'Ereignisse konnten nicht zum Kalender hinzugefügt werden: ',
      noEventsAdded: 'Es wurden keine Ereignisse zum Kalender hinzugefügt.',
      header: 'Fügen Sie Action for Happiness Aktivitäten für einen ganzen Monat in Ihren Kalender ein.',
    },
    [LANGUAGES.ZH]: {
      title: '快乐行动',
      noEvent: '这一天没有活动',
      addToCalendar: '将所有活动添加到日历',
      addingEvents: '正在添加活动...',
      eventsAdded: '活动已成功添加到日历！',
      addEventError: '无法将活动添加到日历：',
      noEventsAdded: '没有活动被添加到日历中。',
      header: '将Action for Happiness活动添加到您的日历中，持续整整一个月。',
    },
    [LANGUAGES.HI]: {
      title: 'खुशी के लिए कार्रवाई',
      noEvent: 'इस दिन के लिए कोई कार्यक्रम नहीं',
      addToCalendar: 'सभी कार्यक्रमों को कैलेंडर में जोड़ें',
      addingEvents: 'कार्यक्रम जोड़े जा रहे हैं...',
      eventsAdded: 'कार्यक्रम सफलतापूर्वक कैलेंडर में जोड़े गए!',
      addEventError: 'कैलेंडर में कार्यक्रम जोड़ने में विफल: ',
      noEventsAdded: 'कैलेंडर में कोई कार्यक्रम नहीं जोड़ा गया।',
      header: 'पूरे महीने के लिए अपने कैलेंडर में Action for Happiness गतिविधियाँ जोड़ें।',
    },
    [LANGUAGES.PT]: {
      title: 'Ação para a Felicidade',
      noEvent: 'Nenhum evento para este dia',
      addToCalendar: 'Adicionar todos os eventos ao calendário',
      addingEvents: 'Adicionando eventos...',
      eventsAdded: 'Eventos adicionados ao calendário com sucesso!',
      addEventError: 'Falha ao adicionar eventos ao calendário: ',
      noEventsAdded: 'Nenhum evento foi adicionado ao calendário.',
      header: 'Adicione atividades do Action for Happiness ao seu calendário por um mês inteiro.',
    },
  };