import { LANGUAGES } from './languages';

// Define which column corresponds to which language
export const LANGUAGE_COLUMNS = {
  [LANGUAGES.EN]: 1, // Column B
  [LANGUAGES.PL]: 2, // Column C
  [LANGUAGES.RU]: 3, // Column D
  [LANGUAGES.ES]: 4, // Column E
  [LANGUAGES.DE]: 5, // Column F
  [LANGUAGES.ZH]: 6, // Column G
  [LANGUAGES.HI]: 7, // Column H
  [LANGUAGES.PT]: 8, // Column I
};

export function initGoogleApi() {
  return new Promise((resolve, reject) => {
    if (window.google) {
      resolve();
    } else {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    }
  });
}

export async function fetchAllEvents() {
  const SHEET_ID = process.env.REACT_APP_SHEET_ID;
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const range = `A:${String.fromCharCode(65 + Math.max(...Object.values(LANGUAGE_COLUMNS)))}`;

  try {
    const response = await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${range}?key=${API_KEY}`
    );
    const data = await response.json();
    
    if (data.values && data.values.length > 0) {
      return data.values.filter(row => 
        row.length > Math.max(...Object.values(LANGUAGE_COLUMNS)) && 
        Object.values(LANGUAGE_COLUMNS).some(col => row[col].trim() !== '')
      );
    }
    return [];
  } catch (error) {
    console.error('Error fetching data from Google Sheets:', error);
    throw error;
  }
}

export async function addEventsToCalendar(events, accessToken, language) {
  const calendarEvents = events.map(event => {
    // Parse the date string, assuming it's in the format 'YYYY-MM-DD'
    const [year, month, day] = event[0].split('-').map(Number);
    const eventDate = new Date(year, month - 1, day); // month is 0-indexed in JavaScript Date
    
    // Check if the date is valid
    if (isNaN(eventDate.getTime())) {
      console.error(`Invalid date: ${event[0]}`);
      return null; // Skip this event
    }

    const nextDay = new Date(eventDate);
    nextDay.setDate(nextDay.getDate() + 1);

    const eventDescription = event[LANGUAGE_COLUMNS[language]];

    return {
      summary: eventDescription,
      start: {
        date: eventDate.toISOString().split('T')[0],
        timeZone: 'Europe/Warsaw',
      },
      end: {
        date: nextDay.toISOString().split('T')[0],
        timeZone: 'Europe/Warsaw',
      },
    };
  }).filter(event => event !== null); // Remove any null events

  try {
    const results = await Promise.all(calendarEvents.map(async (calendarEvent) => {
      const response = await fetch('https://www.googleapis.com/calendar/v3/calendars/primary/events', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(calendarEvent),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to add event to calendar: ${errorData.error.message}`);
      }

      return response.json();
    }));

    console.log('Events created:', results.map(result => result.htmlLink));
    return results;
  } catch (error) {
    console.error('Error creating calendar events:', error);
    throw error;
  }
}
export async function handleAddToCalendar(events, language) {
  try {
    await initGoogleApi();

    return new Promise((resolve, reject) => {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/calendar.events',
        callback: async (response) => {
          if (response.error) {
            console.error('Error getting access token:', response.error);
            reject(new Error(response.error));
          } else {
            try {
              const result = await addEventsToCalendar(events, response.access_token, language);
              resolve(result);
            } catch (error) {
              reject(error);
            }
          }
        },
      });

      client.requestAccessToken();
    });
  } catch (error) {
    console.error('Error adding events to calendar:', error);
    throw error;
  }
}